<template>
    <div>
        <nav
            ref="trapTarget"
            class="navigation-bar"
            :class="[navigation.mainMenuOpen ? 'navigation-bar--active' : '', isResizing ? 'navigation-bar--resizing' : '']"
        >
            <div
                class="navigation-bar__sidebar"
                @click="toggleNav"
            >
                <button class="navigation-bar__menu">
                    <template v-if="!navigation.mainMenuOpen">
                        <div class="sr-only">Open menu</div>
                        <BaseIcon
                            icon="menu"
                            aria-hidden="true"
                        />
                    </template>
                    <template v-else>
                        <div class="sr-only">Sluit menu</div>
                        <BaseIcon
                            icon="close"
                            aria-hidden="true"
                        />
                    </template>
                </button>
            </div>
            <div
                class="navigation-bar__container"
            >
                <div
                    v-show="navigation.mainMenuOpen && primaryMenu && primaryMenu.length"
                    class="navigation-bar__nav-primary"
                >
                    <CraftLink
                        v-for="(link, index) in primaryMenu"
                        :key="`primary-menu-${index}`"
                        :link="link.linkField"
                    >
                        {{ link.linkField.text }}
                    </CraftLink>
                </div>

                <div
                    v-show="navigation.mainMenuOpen"
                    class="navigation-bar__nav-secondary"
                >
                    <!--Dynamic links-->
                    <template v-if="secondaryMenu && secondaryMenu.length">
                        <CraftLink
                            v-for="(link, index) in secondaryMenu"
                            :key="`secondary-menu-${index}`"
                            :link="link.linkField"
                        >
                            {{ link.linkField.text }}
                        </CraftLink>
                    </template>

                    <!--Static links-->
                    <NuxtLink
                        to="https://vfonds.force.com/"
                    >
                        {{ $t('navbar.secondarynav.login') }}
                        <BaseIcon
                            icon="person"
                        />
                    </NuxtLink>
                    <NuxtLink
                        to="/zoek"
                    >
                        {{ $t('navbar.secondarynav.search') }}
                        <BaseIcon
                            icon="search"
                        />
                    </NuxtLink>
                </div>

                <div
                    v-show="navigation.mainMenuOpen"
                    class="navigation-bar__cta"
                >
                    <template
                        v-for="(callToAction, index) in callToActions"
                        :key="index"
                    >
                        <CraftLink
                            class="dn-button base-button"
                            :link="callToAction.linkField"
                            data-location="navigation"
                        >
                            {{ callToAction.linkField.customText ?? callToAction.title }}
                        </CraftLink>
                    </template>
                </div>
            </div>
            <div
                class="navigation-bar__overlay"
                @click="toggleNav"
            />
        </nav>
    </div>
</template>

<script setup>
import MainNavigationQuery from '~/graphql/queries/global/MainNavigation.graphql';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useNavigationStore } from '~/store/navigation';
const navigation = useNavigationStore();

const trapTarget = ref(null);

const { activate, deactivate } = useFocusTrap(trapTarget);

const { variables: defaultVariables } = useCraftGraphql();
const variables = computed(() => {
    return {
        ...defaultVariables,
        uri: undefined // Ignore the URI in this case
    };
});

// Query navigation data
const { data } = await useLazyAsyncQuery({
    query: MainNavigationQuery,
    variables
});

// Format menu items
const primaryMenu = computed(() => data?.value?.globalSets[0].primaryNavigation);
const secondaryMenu = computed(() => data?.value?.globalSets[0].secondaryNavigation);
const callToActions = computed(() => data?.value?.globalSets[0].callToAction);

const { isResizing } = useIsWindowResizing();

const toggleNav = () => {
    navigation.toggleMainMenu();

    if (navigation.mainMenuOpen) {
        activate();
        document.body.classList.add('body--nav-open');
    } else {
        deactivate();
        document.body.classList.remove('body--nav-open');
    }
};

const route = useRoute();

watch(() => route.path, () => {
    navigation.closeMainMenu();
    deactivate();
    document.body.classList.remove('body--nav-open');
});
</script>

<style lang="less" src="./TheNavBar.less"></style>
