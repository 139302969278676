<template>
    <div>
        <footer
            id="footer-landmark"
            class="footer template"
        >
            <div class="footer__container">
                <div class="footer__general-info">
                    <h3
                        v-if="title"
                        class="footer__title"
                    >
                        {{ title }}
                    </h3>

                    <div class="footer__cta">
                        <template
                            v-for="(callToAction, key) in callToActions"
                            :key="key"
                        >
                            <CraftLink
                                class="dn-button base-button"
                                :link="callToAction.linkField"
                            >
                                {{ callToAction.title }}
                            </CraftLink>
                        </template>
                    </div>

                    <div class="footer__rich-text" v-html="richText" /> <!-- eslint-disable-line vue/no-v-html -->
                </div>

                <nav
                    v-if="footerColumnOne"
                    class="footer__primary-navigation"
                >
                    <ul>
                        <li
                            v-for="(link, index) in footerColumnOne"
                            :key="`footer-primary-${index}`"
                        >
                            <CraftLink :link="link.linkField">
                                {{ link.linkField.text }}
                            </CraftLink>
                        </li>
                    </ul>
                </nav>

                <nav
                    v-if="footerColumnTwo"
                    class="footer__secondary-navigation"
                >
                    <ul>
                        <li
                            v-for="(link, index) in footerColumnTwo"
                            :key="`footer-secondary-${index}`"
                        >
                            <CraftLink :link="link.linkField">
                                {{ link.linkField.text }}
                            </CraftLink>
                        </li>
                    </ul>
                </nav>

                <div class="footer__certificate">
                    <!-- two images-->
                    <a
                        href="https://www.belastingdienst.nl/wps/wcm/connect/nl/aftrek-en-kortingen/content/anbi-status-controleren"
                        target="_blank"
                    >
                        <img
                            src="../../assets/img/ANBI.png"
                            alt="ANBI status"
                        >
                    </a>
                    
                    <a
                        href="https://cbf.nl/organisaties/vfonds"
                        target="_blank"
                    >
                        <img
                            src="../../assets/img/CBF.png"
                            alt="CBF erkend"
                        >
                    </a>
                </div>

                <div class="footer__partners">
                    <!-- add partner logos -->
                    <h4>
                        {{ $t('footer.partners') }}
                    </h4>
                    <div class="footer__partners--container">
                        <a
                            href="https://www.postcodeloterij.nl/goede-doelen/overzicht/vfonds"
                            target="_blank"
                        >
                            <img
                                src="../../assets/img/nationale-postcode-loterij.png"
                                alt="Nationale Postcode Loterij"
                            >
                        </a>
                        <a
                            href="https://www.vriendenloterij.nl/over/overzicht/vfonds"
                            target="_blank"
                        >
                            <img
                                src="../../assets/img/vriendenloterij.png"
                                alt="Vriendenloterij"
                            >
                        </a>
                        <a
                            href="https://www.nederlandseloterij.nl/spelen-helpt/goededoelen/vfonds"
                            target="_blank"
                        >
                            <img
                                src="../../assets/img/nederlandse-loterij.png"
                                alt="Nederlandse Loterij"
                            >
                        </a>
                    </div>
                </div>

                <div class="footer__bottom">
                    <nav
                        v-if="privacyNavigation"
                        class="footer__privacy-navigation"
                    >
                        <ul>
                            <li>
                                © Vfonds {{ year }}
                            </li>
                            <li
                                v-for="(link, index) in privacyNavigation"
                                :key="`footer-privacy-${index}`"
                            >
                                <CraftLink :link="link.linkField">
                                    {{ link.linkField.text }}
                                </CraftLink>
                            </li>
                            <li>
                                <a href="javascript: Cookiebot.renew()">{{ $t('footer.cookies') }}</a>
                            </li>
                        </ul>
                    </nav>

                    <div class="footer__credits">
                        <!-- add credits -->
                        <h6>
                            {{ $t('footer.credits.branding') }}
                            <a
                                href="https://www.thonik.nl/"
                                target="_blank"
                            >
                                {{ $t('footer.credits.thonik') }}
                            </a>
                        </h6>
                        <h6>
                            {{ $t('footer.credits.website') }}
                            <a
                                href="https://www.digitalnatives.nl?utm_source=vfonds&utm_campaign=backlink&utm_medium=referral"
                                target="_blank"
                            >
                                {{ $t('footer.credits.digital-natives') }}
                            </a>
                        </h6>
                    </div>

                    <div class="footer__socials">
                        <!-- add socials -->
                        <a
                            href="https://www.linkedin.com/company/vfonds/"
                            target="_blank"
                        >
                            <span class="sr-only">LinkedIn</span>
                            <BaseIcon
                                icon="linkdin"
                                aria-hidden="true"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/vfonds/"
                            target="_blank"
                        >
                            <span class="sr-only">Instagram</span>
                            <BaseIcon
                                icon="instagram"
                                aria-hidden="true"
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/vfonds"
                            target="_blank"
                        >
                            <span class="sr-only">Facebook</span>
                            <BaseIcon
                                icon="facebook"
                                aria-hidden="true"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script setup>
import FooterQuery from '~/graphql/queries/global/Footer.graphql';

const { variables } = useCraftGraphql();
// Ignore the URI in this case
delete variables.uri;

const { data } = await useLazyAsyncQuery({
    query: FooterQuery,
    variables
});

const footerData = computed(() => data?.value?.globalSets[0] ?? []);

// Format menu items
const footerColumnOne = computed(() => footerData.value.footerColumnOne);
const footerColumnTwo = computed(() => footerData.value.footerColumnTwo);
const privacyNavigation = computed(() => footerData.value.privacyNavigation);
const callToActions = computed(() => footerData.value.callToAction);
const title = computed(() => footerData.value.title);
const richText = computed(() => footerData.value.richText);

const year = new Date().getFullYear();
</script>

<style lang="less" src="./TheFooter.less" />
