<template>
    <div>
        <div
            ref="focus"
            class="skip-links"
            tabindex="-1"
        >
            <BaseButton
                class="skip-links__link"
                element="a"
                href="#main-content-landmark"
            >
                Jump to main content
            </BaseButton>
            <BaseButton
                class="skip-links__link"
                element="a"
                href="#footer-landmark"
            >
                Jump to footer
            </BaseButton>
        </div>
    </div>
</template>

<script setup>
const router = useRouter();
const focus = ref(null);

watch(
    () => router.currentRoute.value,
    () => {
        if (focus.value) {
            focus.value.focus();
        }
    },
    { immediate: true }
);
</script>

<style lang="less" src="./SkipLinks.less"></style>
