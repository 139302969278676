import { defineStore } from 'pinia';

// #todo delete un-used functions in file
export const useNavigationStore = defineStore('navigation-store', {
    state: () => {
        return {
            mainMenuOpen: false
        };
    },

    getters: {
        isMainMenuOpen() {
            return this.mainMenuOpen;
        }
    },

    actions: {
        // Menu overlay
        toggleMainMenu() {
            this.mainMenuOpen = !this.mainMenuOpen;
        },

        openMainMenu() {
            this.mainMenuOpen = true;
        },

        closeMainMenu() {
            this.mainMenuOpen = false;
        }
    }
});
