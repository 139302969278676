export function useIsWindowResizing() {
    const isResizing = ref(false);

    function handler() {
        isResizing.value = true;
    }

    onMounted(() => window.addEventListener('resize', handler, { passive: true }));
    onUnmounted(() => window.removeEventListener('resize', handler));

    watch(() => isResizing.value, () => {
        setTimeout(() => isResizing.value = false, 500);
    });

    return { isResizing };
}
