<template>
    <div>
        <!--
            Skiplinks link to landmarks in the website.
            Don't forget to add the IDs below to your custom elements.
            Keep in mind there can only be 1 <main> and 1 <footer> component in the context of the body
        -->
        <SkipLinks />

        <TheLogo />

        <TheNavBar />

        <slot />

        <TheFooter />
    </div>
</template>

<script setup>
import { useNavigationStore } from '~/store/navigation';

const i18nHead = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true
});

const navigationStore = useNavigationStore();

useHead({
    htmlAttrs: {
        ...i18nHead.value.htmlAttrs
    },
    bodyAttrs: {
        'menu-open': navigationStore.isMainMenuOpen,
    }
});
</script>
